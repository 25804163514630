<template>
  <div>
    <div class="pos intro-y grid grid-cols-12 gap-5 mt-5">
      <!-- BEGIN: Post Content -->
      <div class="intro-y col-span-12 lg:col-span-12">
        <div class="col-span-8 lg:col-span-8 mb-4">
          <input
            v-model="billCodes"
            type="text"
            class="intro-y input input--lg w-full box pr-10"
            placeholder="Nhập mã vận đơn"
          />
        </div>

        <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
          <button
            class="button text-white bg-theme-1 shadow-md flex items-center"
            @click="getTracking"
          >
            <EyeIcon class="w-4 h-4 mr-2" />
            Tìm kiếm
          </button>
        </div>
        <template v-if="trackings.length > 0">
          <template v-for="tracking in trackings" :key="tracking.billcode">
            <div class="post intro-y overflow-hidden box mt-5">
              <div class="post__content tab-content">
                <div id="content" class="tab-content__pane active">
                  <div
                    class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
                  >
                    <div
                      class="font-medium flex items-center border-b border-gray-200 dark:border-dark-5 pb-5"
                    >
                      <ChevronDownIcon class="w-4 h-4 mr-2" />
                      Mã vận đơn của quý khách:
                      <span style="color: red">{{ tracking.billcode }}</span>
                    </div>
                    <div class="mt-5">
                      <div class="grid grid-cols-4 gap-6">
                        <div class="col-span-1 box dark:bg-dark-6 p-5">
                          <div class="font-bold text-theme-1 text-center">
                            Người nhận
                          </div>
                          <div class="text-center">
                            {{ tracking.orderInfo.receiverName }}
                          </div>
                        </div>
                        <div class="col-span-1 box dark:bg-dark-6 p-5">
                          <div class="font-bold text-theme-1 text-center">
                            Điện thoại
                          </div>
                          <div class="text-center">
                            {{ tracking.orderInfo.receiverPhone }}
                          </div>
                        </div>
                        <div class="col-span-1 box dark:bg-dark-6 p-5">
                          <div class="font-bold text-theme-1 text-center">
                            Tên hàng hoá
                          </div>
                          <div class="text-center">
                            {{ tracking.orderInfo.goodsName }}
                          </div>
                        </div>
                        <div class="col-span-1 box dark:bg-dark-6 p-5">
                          <div class="font-bold text-theme-1 text-center">
                            COD(VND)
                          </div>
                          <div class="text-center">
                            {{ $h.formatCurrency(tracking.orderInfo.cod) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="tab-content__pane active overflow-y-auto"
                >
                  <div
                    class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
                  >
                    <!-- BEGIN: Recent Activities -->
                    <div class="col-span-10 mt-3">
                      <div class="intro-x flex items-center h-5">
                        <h2 class="text-lg font-medium truncate mr-5">
                          Hành trình
                        </h2>
                      </div>
                      <div class="report-timeline mt-5 relative">
                        <div
                          class="intro-x relative flex items-center mb-3"
                          v-for="(tracesList, index) in tracking.traceLists[0]?.details"
                          :key="index"
                        >
                          <div class="report-timeline__image">
                            <div
                              class="w-10 h-10 flex-none image-fit rounded-full overflow-hidden bg-theme-2"
                            ></div>
                          </div>
                          <div class="box px-5 py-3 ml-4 flex-1 zoom-in">
                            <div class="flex items-center">
                              <div class="font-medium">
                                {{ tracesList.desc }}
                              </div>
                              <div class="text-xs text-red-500 ml-auto">
                                {{ tracesList.scantype }}
                              </div>
                            </div>
                            <div class="text-red-500 mt-1">
                              {{ tracesList.scantime }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- END: Recent Activities -->
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else>
          <div
            class="grid grid-cols-2 sm:gap-6 row-gap-6 box p-5"
            v-if="loading"
          >
            <div
              class="col-span-6 sm:col-span-3 xl:col-span-2 flex flex-col justify-end items-center"
            >
              <LoadingIcon class="w-8 h-8" />
            </div>
          </div>
        </template>
      </div>
      <!-- END: Post Content -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { orderService } from "@/services/order";
import { showError } from "@/libs/notification";
import { TracesList, OrderInfo } from "@/configs/tracking";
import { OrderPayload } from "@/configs/orders";

export default defineComponent({
  setup() {
    const route = useRoute();
    const billCodes = ref((route.query.billCode as string) || "");
    const loading = ref(false);

    type Tracking = {
      billcode: string;
      traceLists: TracesList[];
      orderInfo: OrderInfo;
    };

    const trackings = ref<Tracking[]>([]);

    const preparePayload = (): OrderPayload => {
      return {
        search: billCodes.value,
        page: 1,
        pageSize: 10,
        startDate: "2018-02-20",
        endDate: "2018-02-20"
      };
    };

    const getTracking = async () => {
      loading.value = true;
      const length = billCodes.value.split(",").length
      if (length > 10) {
        showError("Vui lòng chọn tối đa 10 mã vận đơn");
        return;
      }
      try {
        const resultTracking = await orderService.getTrackingMultiple(billCodes.value);
        const resultOrders = (await orderService.getOrders(preparePayload())).result.orders;
        const result: Tracking[] = [];

        resultTracking.forEach((tracking: any) => {
          const billcode = tracking.tracesList[0].billcode;
          const orderIndo = resultOrders.find((order: any) => order.billCode === billcode);
          result.push({
            billcode: billcode,
            traceLists: tracking.tracesList,
            orderInfo: orderIndo
          });
        });
        trackings.value = result;
        console.log(trackings.value)
      } catch (error) {
        showError(error.response ? error.response.data.error : "Co loi xay ra");
        trackings.value = [];
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      if (billCodes.value) {
        getTracking();
      }
    });

    watch(billCodes, (val) => {
      if (val.includes(" ")) {
        billCodes.value = val.replaceAll(" ", ",");
      }
    });

    return {
      billCodes,
      loading,
      trackings,
      getTracking
    };
  }
});
</script>
