
import { defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { orderService } from "@/services/order";
import { showError } from "@/libs/notification";
import { TracesList, OrderInfo } from "@/configs/tracking";
import { OrderPayload } from "@/configs/orders";

export default defineComponent({
  setup() {
    const route = useRoute();
    const billCodes = ref((route.query.billCode as string) || "");
    const loading = ref(false);

    type Tracking = {
      billcode: string;
      traceLists: TracesList[];
      orderInfo: OrderInfo;
    };

    const trackings = ref<Tracking[]>([]);

    const preparePayload = (): OrderPayload => {
      return {
        search: billCodes.value,
        page: 1,
        pageSize: 10,
        startDate: "2018-02-20",
        endDate: "2018-02-20"
      };
    };

    const getTracking = async () => {
      loading.value = true;
      const length = billCodes.value.split(",").length
      if (length > 10) {
        showError("Vui lòng chọn tối đa 10 mã vận đơn");
        return;
      }
      try {
        const resultTracking = await orderService.getTrackingMultiple(billCodes.value);
        const resultOrders = (await orderService.getOrders(preparePayload())).result.orders;
        const result: Tracking[] = [];

        resultTracking.forEach((tracking: any) => {
          const billcode = tracking.tracesList[0].billcode;
          const orderIndo = resultOrders.find((order: any) => order.billCode === billcode);
          result.push({
            billcode: billcode,
            traceLists: tracking.tracesList,
            orderInfo: orderIndo
          });
        });
        trackings.value = result;
        console.log(trackings.value)
      } catch (error) {
        showError(error.response ? error.response.data.error : "Co loi xay ra");
        trackings.value = [];
      } finally {
        loading.value = false;
      }
    };

    onMounted(() => {
      if (billCodes.value) {
        getTracking();
      }
    });

    watch(billCodes, (val) => {
      if (val.includes(" ")) {
        billCodes.value = val.replaceAll(" ", ",");
      }
    });

    return {
      billCodes,
      loading,
      trackings,
      getTracking
    };
  }
});
